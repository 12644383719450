import React, { useState, useEffect, useCallback } from "react";
import Chart from "react-apexcharts";
import {
  Grid,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  styled,
  Button,
  TableFooter,
  TablePagination,
  TextField,
} from "@mui/material";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import * as XLSX from "xlsx";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#d3d4ee",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
  padding: "8px 12px",
}));

const TableWrapper = styled(Box)({
  width: "100%",
});

const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  height: "460px",
  "& .MuiTableBody-root": {
    overflowY: "auto",
    height: "auto",
    minHeight: "calc(6 * 56px)",
  },
}));

const DialogContentWrapper = styled(Box)({
  position: "relative",
  paddingBottom: "80px", // Adjust as needed based on footer height
});

const BarChart = ({
  selectedSalesgroup,
  selectedStockist,
  selectedNames,
  customDateRange,
}) => {
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });
  const [selectedCategory, setSelectedCategory] = useState("All"); // Default to 'All'
  const [availableCategories, setAvailableCategories] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [viewData, setViewData] = useState([]); // State to store detailed data
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // Debounced search term
  const [totalKg, setTotalKg] = useState(0);
  const [totalPkt, setTotalPkt] = useState(0);
  const [selectedValueType, setSelectedValueType] = useState({
    category: "",
    value: "",
  });
  const [overallTotal, setOverallTotal] = useState({ kg: 0, pkt: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const options = {
    chart: {
      id: "basic-bar",
      height: 350,
      stacked: true,
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const categoryIndex = config.dataPointIndex;
          const seriesIndex = config.seriesIndex;
          const category = chartData.categories[categoryIndex];
          const seriesName = chartData.series[seriesIndex].name;
          const type = seriesName.split(" - ")[1];

          fetchQuantityData(category, type, selectedSalesgroup);
          setSelectedValueType({ category });
        },
      },
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        formatter: function (val) {
          return val; // Optionally format category labels if needed
        },
      },
      tooltip: {
        enabled: true, // Enable tooltip for X-axis
      },
    },
    yaxis: {
      type: "logarithmic", // Use logarithmic scale for Y-axis
      labels: {
        formatter: function (val) {
          return val.toLocaleString(); // Format Y-axis labels (Kg/Pkt values)
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "40%",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    labels: {
      style: {
        colors: ["#333"],
      },
    },
    colors: ["#1E3A8A", "#D97706"],
    fill: {
      colors: ["#1E3A8A", "#D97706"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString(); // Format tooltip values
        },
      },
    },
  };

  // Debounce search term
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm); // Update debounced search term after 1000ms of inactivity
      setCurrentPage(0);
    }, 1000);

    return () => clearTimeout(delayDebounceFn); // Clear timeout if input changes
  }, [searchTerm]);

  const fetchQuantityData = async (category, type, selectedSalesgroup) => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();

      params.append("page", currentPage + 1);
      params.append("per_page", rowsPerPage);

      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", selectedStockist);
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }

      params.append("cat", category);
      // params.append("unit", type);

      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }

      // Search query
      if (debouncedSearchTerm) {
        params.append("query_string", debouncedSearchTerm);
      }

      const response = await getRequestInstanceOne(
        `/api/category_sku_details?${params.toString()}`
      );

      const totalRecords = response.data[0].totalCount[0].total;

      setOpenDialog(true);
      setViewData(response.data[0].results);
      setTotalCount(totalRecords);

      // Calculate total quantity from the response data
      let totalQuantity = 0;
      response.data[0].totals.forEach((item) => {
        // if (item.unit === 'Kg') {
        totalQuantity += parseInt(item.total_quantity_sum) || 0; // Sum up kg data
        // } else if (item.unit === 'Pkt') {
        //     totalQuantity += item.total_quantity || 0; // Sum up pkt data
        // }
      });

      // const filteredData = response.filter(item => item.unit === 'Kg' || item.unit === 'Pkt');
      // Update state to show the total quantity in CustomDialog
      setSelectedValueType({
        category: category,
        value: totalQuantity.toString(), // Convert to string for display
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuantityData(selectedValueType.category);
  }, [
    currentPage,
    rowsPerPage,
    customDateRange,
    selectedSalesgroup,
    selectedStockist,
    selectedNames,
    debouncedSearchTerm, // Trigger data fetch when debounced search term changes
  ]);

  const calculateTotals = (data) => {
    let kgTotal = 0;
    let pktTotal = 0;

    //   const traverseData = (obj) => {
    //     for (const key in obj) {
    //       if (typeof obj[key] === "object" && obj[key] !== null) {
    //         traverseData(obj[key]);
    //       } else if (key.trim() === "Kg") {
    //         kgTotal += obj[key];
    //       } else if (key.trim() === "Pkt") {
    //         pktTotal += obj[key];
    //       }
    //     }
    //   };

    //   if (Array.isArray(data)) {
    //     data.forEach((item) => traverseData(item));
    //   } else {
    //     traverseData(data);
    //   }

    // console.log(data);

    for (let d in data) {
      for (let item in data[d]["items"]) {
        let e = data[d]["items"][item];
        if (e["unit"].trim().toLowerCase() == "pkt") {
          pktTotal += e["total_quantity"];
        } else if (e["unit"].trim().toLowerCase() == "kg") {
          kgTotal += e["total_quantity"];
        }
      }
    }

    return { kgTotal, pktTotal };
  };

  const fetchData = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      console.log("customDateRange in api", customDateRange);
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }
      // const stockistUser = localStorage.getItem("user");
      // if (stockistUser != "admin") {
      //   params.append("user", stockistUser);
      // }
      const response = await getRequestInstanceOne(
        `/counts/grade_qty_api?${params.toString()}`
      );
      console.log("response", response);
      const data = response;
      const { kgTotal, pktTotal } = calculateTotals(data);
      setOverallTotal({
        kg: kgTotal,
        pkt: pktTotal,
      });
      const validCategories = data.filter((categoryData) => {
        return (
          categoryData["category"] != null && categoryData["items"].length > 0
        );
      });

      setRawData(validCategories);
      console.log("data==", validCategories);
      setAvailableCategories(validCategories);
      setFirstData(validCategories); // Always set initial data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [selectedSalesgroup, selectedStockist, selectedNames, customDateRange]);

  useEffect(() => {
    fetchData();

    // Set up polling to fetch data every minute
    // const intervalId = setInterval(() => {
    //     fetchData();
    // }, 60000); // 60 seconds

    // Clean up the interval on component unmount
    // return () => clearInterval(intervalId);
  }, [
    selectedSalesgroup,
    selectedStockist,
    selectedNames,
    customDateRange,
    fetchData,
  ]);

  console.log("overallTotal", overallTotal);
  const setFirstData = (data) => {
    if (data.length > 0) {
      let allCategories = []; // Array to store all category names
      let combinedKgValues = [];
      let combinedPktValues = [];
      console.log("first Data set===", data);

      // Iterate through each category data
      data.forEach((categoryData) => {
        const categoryName = categoryData["category"]; // Get category name
        const categoryValues = categoryData["items"]; // Get category values

        // Initialize total kg and pkt values for the category
        let totalKg = 0;
        let totalPkt = 0;

        // Check if the category values are not an empty object
        if (categoryValues.length > 0) {
          // Iterate over each item in the category
          // categoryValues.forEach((key) => {
          //   const item = categoryValues[key];
          //   totalKg += parseFloat(item.Kg) || 0;

          //   // Normalize Pkt keys by trimming them
          //   const pktKey = Object.keys(item).find((k) => k.trim() === "Pkt");
          //   if (pktKey) {
          //     totalPkt += parseFloat(item[pktKey]) || 0;
          //   }
          // });
          for (let item in categoryValues) {
            let e = categoryValues[item];
            if (e["unit"].trim().toLowerCase() == "pkt") {
              totalPkt += e["total_quantity"];
            } else if (e["unit"].trim().toLowerCase() == "kg") {
              totalKg += e["total_quantity"];
            }
          }

          // Push category name and values to arrays
          allCategories.push(categoryName);
          combinedKgValues.push(totalKg);
          combinedPktValues.push(totalPkt);
        }
      });

      setChartData({
        categories: allCategories, // Set all category names
        series: [
          {
            name: "All - Kg",
            data: combinedKgValues,
          },
          {
            name: "All - Pkt",
            data: combinedPktValues,
          },
        ],
      });
      // Set the selected category to 'All'
      setSelectedCategory("All");
    }
  };

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);

    if (newCategory === "All") {
      setFirstData(rawData);
      const { kgTotal, pktTotal } = calculateTotals(rawData);
      setOverallTotal({
        kg: kgTotal,
        pkt: pktTotal,
      });
    } else {
      const selectedCategoryData = rawData.find(
        (item) => Object.keys(item)[0] === newCategory
      );
      console.log("selectedCategoryData", selectedCategoryData);
      if (selectedCategoryData) {
        const { kgTotal, pktTotal } = calculateTotals(
          selectedCategoryData[newCategory]
        );
        setOverallTotal({
          kg: kgTotal,
          pkt: pktTotal,
        });

        const categoryValues = selectedCategoryData[newCategory];

        // Initialize kgValues and pktValues arrays
        const kgValues = [];
        const pktValues = [];

        // Iterate over the keys in categoryValues
        Object.keys(categoryValues).forEach((key) => {
          const item = categoryValues[key];
          console.log("item", item);
          kgValues.push(item.Kg || 0);

          // Normalize Pkt keys by trimming them
          const pktKey = Object.keys(item).find(
            (k) => k.trim().toLowerCase() === "pkt"
          );
          pktValues.push(item[pktKey] || 0);
        });

        // Set chart data for the selected category
        setChartData({
          categories: Object.keys(categoryValues),
          series: [
            {
              name: `${newCategory} - Kg`,
              data: kgValues,
            },
            {
              name: `${newCategory} - Pkt`,
              data: pktValues,
            },
          ],
        });
      }
    }
  };

  const calculateTotalAmount = () => {
    return viewData
      .reduce((total, item) => {
        return (
          total +
          Math.round(
            parseFloat(item.total_price) * parseFloat(item.total_quantity)
          )
        );
      }, 0)
      .toFixed(2);
  };

  const calculateTotal = (field) => {
    return viewData
      .reduce((total, item) => total + parseFloat(item[field] || 0), 0)
      .toFixed(2);
  };

  const exportExcel = async () => {
    try {
      // Define your params
      const params = new URLSearchParams({
        cat: selectedValueType.category,
        start_date: customDateRange.start_date,
        end_date: customDateRange.end_date,
      });

      // Fetch data using getRequestInstanceOne
      const response = await getRequestInstanceOne(
        `/api/category_sku_details?${params.toString()}`
      );

      // Assuming 'response.data' contains the API data
      const viewData = response.data[0].results; // Accessing the 'results' array

      // Calculate totals
      let totalPrice = 0;
      let totalQuantity = 0;
      let totalAmount = 0;

      // Map data and calculate totals
      const ws_data = viewData.map((row) => {
        totalPrice += parseFloat(row.total_price) || 0;
        totalQuantity += parseFloat(row.total_quantity) || 0;
        totalAmount += parseFloat(row.total_amount) || 0;

        return [
          row.itemId,
          row.payerId,
          row.unit,
          row.total_price.toLocaleString(), // Format price
          row.total_quantity.toLocaleString(), // Format quantity
          row.total_amount.toLocaleString(), // Format total amount
        ];
      });

      // Format the total values with commas
      const formattedTotalPrice = totalPrice.toLocaleString();
      const formattedTotalQuantity = totalQuantity.toLocaleString();
      const formattedTotalAmount = totalAmount.toLocaleString();

      // Add total row to data array
      const totalRow = [
        "Total",
        "",
        "",
        formattedTotalPrice,
        formattedTotalQuantity,
        formattedTotalAmount,
      ];
      ws_data.push(totalRow);

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.aoa_to_sheet([
        ["Item ID", "PayerId", "Unit", "Price", "Quantity", "Total Amount"],
        ...ws_data,
      ]);

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, "Details");

      // Export to Excel file
      XLSX.writeFile(
        wb,
        `${selectedValueType.category}_${customDateRange.start_date}_${customDateRange.end_date}.xlsx`
      );
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <Grid container spacing={2}>
      <CustomLoader open={isLoading} />
      <Grid item xs={12} sm={4}>
        <Typography variant="h6" align="center">
          Category wise Order
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box display="flex" justifyContent="center">
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "#5052ba",
              padding: "8px",
              mr: 2,
              borderRadius: "4px",
              color: "wheat",
            }}
          >
            Kg: {overallTotal?.kg}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "rgba(217, 119, 6, 0.85)",
              padding: "8px",
              borderRadius: "4px",
              color: "white",
            }}
          >
            Pkt: {overallTotal?.pkt}
          </Typography>
        </Box>
      </Grid>
      {/* <Grid item xs={12} sm={4}>
        <FormControl fullWidth size="small">
          <InputLabel>Select Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            label="Select Category"
          >
            <MenuItem value="All">All</MenuItem>
            {availableCategories.map((category, index) => {
              console.log(category);
              return (
                <MenuItem key={index} value={category["category"] || "Unknown"}>
                  {category["category"] || "Unknown"}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid> */}
      <Grid item xs={12}>
        {chartData.series &&
        chartData.series.length > 0 &&
        chartData.series.some((s) => s.data.length > 0) ? (
          <Chart
            options={options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        ) : (
          <div
            style={{
              height: "350px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            No data is available
          </div>
        )}
      </Grid>
      <CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={`View Quantity - (${selectedValueType.category} = ${selectedValueType.value})`}
        fullscreen={true} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
      >
        <Grid container spacing={2}>
          <CustomLoader open={isLoading} />

          {/* Search Field */}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
            />
          </Grid>

          <Grid item xs={12} sm={9}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <Typography
                variant="subtitle2"
                size="small"
                sx={{
                  backgroundColor: "#5052ba",
                  padding: "8px",
                  mr: 2,
                  borderRadius: "4px",
                  color: "wheat",
                }}
              >
                Total Count: {totalCount}
              </Typography>

              <Button
                size="small"
                variant="contained"
                onClick={exportExcel}
                sx={{
                  backgroundColor: "#5052ba",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4042a3",
                  },
                  ml: 2,
                  p: 1,
                }}
                startIcon={<SaveAltIcon />}
              >
                Export Excel
              </Button>
            </Box>
          </Grid>
        </Grid>

        <TableWrapper>
          <TableContainerWrapper component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Item ID</StyledTableCell>
                  <StyledTableCell>Payer ID</StyledTableCell>
                  <StyledTableCell>Unit</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Quantity</StyledTableCell>
                  <StyledTableCell>Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {viewData.map((item, index) => (
                  <StyledTableRow key={index}>
                    <TableCell align="center">{item.itemId}</TableCell>
                    <TableCell align="center">{item.payerId}</TableCell>
                    <TableCell align="center">{item.unit}</TableCell>
                    <TableCell align="center">{item.total_price}</TableCell>
                    <TableCell align="center">{item.total_quantity}</TableCell>
                    <TableCell align="center">
                      {Math.round(
                        parseFloat(item.total_price) *
                          parseFloat(item.total_quantity)
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="center">
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <strong>{calculateTotal("total_price")}</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{calculateTotal("total_quantity")}</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{calculateTotalAmount()}</strong>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainerWrapper>
        </TableWrapper>
        {/* Pagination Controls */}
        <TablePagination
          component="div"
          count={totalCount}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </CustomDialog>
    </Grid>
  );
};

export default BarChart;
