import React, { useState, useEffect, useCallback } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DeleteForeverOutlined } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  TablePagination,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Grid,
  Typography,
  TextField,
  Stack,
  InputAdornment,
  Tooltip,
  Autocomplete,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ImageIcon from "@mui/icons-material/Image";
import { green, purple, red, teal } from "@mui/material/colors";
import toast, { Toaster } from "react-hot-toast";

import { useSelector, useDispatch } from "react-redux";
import { updateSelectedFilterAllOrder, updateCustomDateRangeAllOrder } from "../../../../feature/dateFilter/dateFilterSlice";

import {
  getRequestInstanceOne,
  postRequest,
} from "../../../../apiServices/apiCall";
import { CustomLoader } from "../../../../components/CustomLoader/CustomLoader";
import CustomDialog from "../../../../components/CustomDialog/CustomDialog";

const StyledTableCell = styled(TableCell)(({ theme, isHeader }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: isHeader ? "center" : "left",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
  padding: "8px 12px",
}));

const TableWrapper = styled(Box)({
  width: "100%",
});

const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  height: "350px",
  "& .MuiTableBody-root": {
    overflowY: "auto",
    height: "auto",
    minHeight: "calc(6 * 56px)",
  },
}));

export default function AllOrderTable({
  SettotalRowCount2,
}) {
  const currentDate = new Date().toISOString().split("T")[0];
  const [dateFilter, setDateFilter] = useState(currentDate);
  const [rows, setRows] = useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewDialogImg, setOpenViewDialogImg] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewData, setViewData] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermValue, setSearchTermValue] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null); // New state for debounce timer
  const [openDialog, setOpenDialog] = useState(false);
  const [image, setImage] = useState("");
  const [discount, setDiscount] = useState("");
  const [discounted, setDiscounted] = useState("");

  const dispatch = useDispatch();
  const selectedFilter = useSelector((state) => state.allOrderDateFilter.selectedFilter);
  const customDateRange = useSelector((state) => state.allOrderDateFilter.customDateRange);

  // const getOrderListViewItem = async (params) => {
  //     const orderID = params?.row?.orderId
  //     try {
  //         const result = await getRequestInstanceOne(`/counts/edit_qty/${orderID}`);
  //         // console.log("result", result)
  //         const data = result?.map((item, index) => { return { ...item, id: index, inputError: true } })
  //         setOpenViewDialog(true);
  //         setViewData(data);
  //     } catch (error) {
  //         console.error(error);
  //         setViewData([])
  //     }
  //     //  finally {
  //     //     console.log('inside finally');
  //     // }
  // }

  const handleChangeQuantity = (e, id) => {
    const value = e.target.value;
    const reg = new RegExp("^[0-9]+$");
    const isValid = reg.test(e.target.value);

    const oldData = viewData?.map((item) => {
      if (item?.id === id) {
        return { ...item, quantity: value, inputError: isValid };
      } else {
        return item;
      }
    });
    setViewData(oldData);
    setIsValid(isValid);
  };

  const handleEditItemQuantity = async () => {
    if (isValid) {
      const orderID = viewData[0]?.orderid;
      const oldData = viewData?.map((item) => {
        return { ...item, qty: item?.quantity };
      });

      oldData.forEach((item) => delete item?.quantity);

      try {
        await postRequest(`/counts/edit_qty/${orderID}`, { items: oldData });
        getOrderList();
      } catch (error) {
        console.error(error);
      }
      //  finally {
      //     console.log('inside finally');
      // }
    }
  };

  // total count sending to OrderDetails compo. start
  SettotalRowCount2(totalRowCount);
  // total count sending to OrderDetails compo. end

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOpenDialog(false);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rowsPerPage changes
    setOpenDialog(false);
  };

  const getOrderList = useCallback(
    async () => {
      setIsLoading(true);
      const params = new URLSearchParams({
        page: page + 1,
        per_page: rowsPerPage,
      });
      params.append("start_date", customDateRange.start_date);
      params.append("end_date", customDateRange.end_date);
      const stockistUser = localStorage.getItem("user");
      if (localStorage.getItem("user") != "admin") {
        params.append("user", stockistUser);
      }

      if (searchTermValue) {
        params.append("query_string", searchTermValue);
      }
      try {
        const url = `/api/orderTaking/all-orders-items/order-by-dashboard?${params.toString()}`;
        const result = await getRequestInstanceOne(url);
        const data = result.data[0];

        const restructureData = data.results?.map((items) => ({
          id: items?.id,
          items: items?.items,
          orderId: items?.orderId,
          order_by: items?.order_by?.name,
          payerId: items?.r_info?.payerId,
          s_name: items?.r_info?.s_name,
          customer: items?.r_info?.r_name,
          area: items?.r_info?.area,
          group: items?.r_info?.salesgroup,
          order_at: items?.order_at,
          status: items?.status,
          qty: items?.items
            ?.reduce((total, item) => total + Number(item?.qty), 0)
            .toString(),
          location: items?.location,
          image: items?.image,
        }));

        setRows(restructureData);
        setTotalRowCount(
          searchTermValue
            ? result.data[0]?.count[0].total
            : result.data[0]?.count[0].total
        );
      } catch (error) {
        console.error(error);
        setRows([]);
      } finally {
        setIsLoading(false);
      }
    },
    [dateFilter, page, rowsPerPage, customDateRange, searchTermValue, selectedFilter]
  );

  const handleCustomRangeSubmit = () => {
    const { start_date, end_date } = customDateRange;
    dispatch(updateSelectedFilterAllOrder(`${start_date} to ${end_date}`));
    setOpenDialog(false);
  };

  const handleFilterSelect = (filter) => {
    dispatch(updateSelectedFilterAllOrder(filter));
    const today = new Date();
    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Custom Range":
        setOpenDialog(true);
        return;
      default:
    }
    dispatch(updateCustomDateRangeAllOrder({ start_date, end_date }));
  };

  useEffect(() => {
    getOrderList(); // Ensure it calls the updated customDateRange values
  }, [page, rowsPerPage, searchTermValue, selectedFilter]);
  
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);

    // Clear previous debounce timer
    if (debounceTimer) clearTimeout(debounceTimer);

    // Set new debounce timer
    const newTimer = setTimeout(() => {
      if (event.target.value.trim() !== "") {
        setSearchTermValue(event.target.value); // Trigger search when user stops typing
        setPage(0);
      } else {
        setSearchTermValue(""); // Trigger clear when input is empty
        setPage(0);
      }
    }, 2000); // 2-second delay

    setDebounceTimer(newTimer); // Save the timer to the state
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    dispatch(updateSelectedFilterAllOrder(""));
  };

  const handleDateChange = (field) => (event) => {
    dispatch(updateCustomDateRangeAllOrder({
      ...customDateRange,
      [field]: event.target.value,
    }));
  };

  const handleSearchClick = () => {
    setSearchTermValue(searchTerm);
    setPage(0);
  };

  const handleClearSearch = () => {
    setSearchTermValue("");
    setSearchTerm("");
    setPage(0);
    getOrderList();
  };

  // Making text ellipsis... start

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Making text ellipsis... end

  const handleopenView_img1 = (params) => {
    setImage(params.image);
    setOpenViewDialogImg(true);
  };

  const getOrderListViewItem1 = async (orderid) => {
    setIsLoading(true);
    try {
      // const url = `/api/groupby_accordion_printview?orderid=${orderid}`;
      const url = `/api/all_order_view?start_date=${customDateRange.start_date}&end_date=${customDateRange.end_date}&orderId=${orderid}`;
      const result = await getRequestInstanceOne(url);
      const allOrderViewData = result.data;

      // console.log(allOrderViewData[0].discount_amount[0]);
      setDiscounted(allOrderViewData[0].discount_amount[0]);

      if (allOrderViewData) {
        setOpenViewDialog(true);
        setViewData(allOrderViewData);
      } else {
        setOpenViewDialog(true);
        setViewData([]);
      }
    } catch (error) {
      console.error(error);
      setViewData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleopenView1 = (params) => {
    // console.log("before clicked", params);
    const orderid = params?.orderId;
    const cash_disc = params?.cash_disc;
    if (orderid) {
      getOrderListViewItem1(orderid);
    }
    // console.log("after clicked", orderid);
  };

  let totalQty = 0;
  let totalAmount = 0;
  let totalTPR = 0;
  let totalTax = 0;
  let totalFreePkts = 0;
  let totalCD = 0;

  // Calculate totals
  if (viewData?.length > 0) {
    viewData.forEach((view) => {
      view.itemid[0].forEach((_, idx) => {
        const qty = parseFloat(view?.quantity[0][idx]) || 0;
        const price = parseFloat(view?.price[0][idx]) || 0;
        const total = qty * price;
        const tpr = parseFloat(view?.discount[0][idx]) || 0;
        const tax = parseFloat(view?.tax[0][idx]) || 0;
        const freePkts = parseFloat(view?.free[0][idx]) || 0;
        const cd = parseFloat(view?.cash_disc[0][idx]) || 0;

        totalQty += qty;
        totalAmount += total;
        totalTPR += tpr;
        totalTax += tax;
        totalFreePkts += freePkts;
        totalCD += cd;
      });
    });
  }
  // for delete data
  const DeleteData = async (row) => {
    const orderId = row.orderId;
    const data = false;

    // Ask for user confirmation
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (confirmed) {
      // If the user confirms, proceed with the delete request
      const response = await fetch(
        `http://143.244.136.36:8084/api/delete-order-dashboard?orderId=${orderId}&data=${data}`,
        {
          method: "POST",
        }
      );
      // Refresh the page after the response is received
      if (response.ok) {
        // window.location.reload();
        getOrderList(customDateRange.start_date, customDateRange.end_date);
        toast.success("Successfully Deleted!");
      } else {
        console.error("Failed to delete the data");
      }
    } else {
      console.log("Delete action was canceled by the user.");
    }
  };

  // Calling Put APi for discount

  const updateItemDiscount = async (OrderID, discount_amount) => {
    const url = `http://143.244.136.36:8084/api/items-cd-edit?orderId=${OrderID}&discount_amount=${discount_amount}`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response:", data);
    } catch (error) {
      console.error("Error:", error);
    }
    setDiscount("");
    setDiscounted(discount);
  };

  // USer input value getting

  const UserInputChange = (event) => {
    setDiscount(event.target.value);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <CustomLoader open={isLoading} />
        <Paper sx={{ width: "100%", p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip
                  title={`${customDateRange.start_date} ${customDateRange.end_date}`}
                  placement="top"
                  arrow
                >
                  <Autocomplete
                    disablePortal
                    disableClearable
                    size="small"
                    sx={{ bgcolor: "#fff", width: 200 }}
                    id="combo-box-demo"
                    value={selectedFilter}
                    options={[
                      "Today",
                      "Weekly",
                      "Monthly",
                      "Yearly",
                      "Custom Range",
                    ]}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => handleFilterSelect(value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Date Filter" />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <TextField
                  size="small"
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       {searchTerm && (
                  //         <IconButton onClick={handleClearSearch}>
                  //           <ClearIcon />
                  //         </IconButton>
                  //       )}
                  //       <IconButton onClick={handleSearchClick}>
                  //         <SearchIcon />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                  alignItems: "center",
                }}
              >
              </Grid>
            </Grid>
          </Box>
          <TableWrapper>
            <TableContainerWrapper component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      OrderID
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      OrderBy
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      PayerId
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Stockist
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Beat Name
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Customer
                    </StyledTableCell>
                    {/* <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Area
                    </StyledTableCell> */}
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Group
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Date & Time
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Order_Status
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Quantity
                    </StyledTableCell>
                    <StyledTableCell isHeader>Location</StyledTableCell>
                    <StyledTableCell isHeader>Image</StyledTableCell>
                    <StyledTableCell isHeader>Action</StyledTableCell>
                    {localStorage.getItem("user") === "admin" && (
                      <StyledTableCell isHeader>Delete</StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <Tooltip title={row.orderId || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.orderId || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.order_by || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.order_by || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                        }}
                      >
                        {row.payerId || "-"}
                      </TableCell>
                      <Tooltip title={row.s_name || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.s_name || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.location.beat_name || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.location.beat_name || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.customer || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.customer || "-", 15)}
                        </TableCell>
                      </Tooltip>

                      {/* <Tooltip title={row.area || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.area || "-", 15)}
                        </TableCell>
                      </Tooltip> */}

                      <Tooltip title={row.group || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.group || "-", 15)}
                        </TableCell>
                      </Tooltip>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.order_at || "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {/* {row.location.no_order_reason || "-"} */}

                        {row.qty
                          ? row.qty == 0
                            ? row.location.no_order_reason
                            : row.status
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.qty || "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.location &&
                        row.location.lattitude &&
                        row.location.logitude ? (
                          <a
                            href={`https://www.google.com/maps?q=${row.location.logitude},${row.location.lattitude}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton>
                              <LocationOnIcon />
                            </IconButton>
                          </a>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                          cursor: "not-allowed",
                        }}
                      >
                        <IconButton
                          disabled={
                            row.location.no_order_reason == "Store Closed"
                              ? false
                              : true
                          }
                        >
                          <ImageIcon
                            onClick={() => handleopenView_img1(row)}
                            sx={{
                              color:
                                row.location.no_order_reason == "Store Closed"
                                  ? green["600"]
                                  : green["100"],
                            }}
                          />
                        </IconButton>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          background: "white",
                          padding: "0 5px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          color="inherit"
                          onClick={() => handleopenView1(row)}
                        >
                          View
                        </Button>
                      </TableCell>

                      {localStorage.getItem("user")?.trim() === "admin" && (
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            textAlign: "center",
                          }}
                        >
                          <IconButton>
                            <DeleteForeverOutlined
                              sx={{ color: "red" }}
                              onClick={() => DeleteData(row)}
                            />
                            <Toaster />
                          </IconButton>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainerWrapper>
          </TableWrapper>
          <TablePagination
            component="div"
            count={totalRowCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title="Select Date Range"
        fullscreen={false}
        maxWidth="sm"
      >
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          value={customDateRange.start_date}
          onChange={handleDateChange("start_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          value={customDateRange.end_date}
          onChange={handleDateChange("end_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDialogClose}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCustomRangeSubmit}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              ml: 2,
            }}
          >
            Submit
          </Button>
        </Box>
      </CustomDialog>

      {/* <CustomDialog
        open={openViewDialog}
        onClose={() => {
          setOpenViewDialog(false);
        }}
        title={`View Quantity`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
      >
        <table className="table-order-details-preview">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {viewData?.map((view, index) => (
              <tr key={index}>
                <td>{view?.item_id}</td>
                <td>{view?.price}</td>
                <td>{view?.discount}</td>
                <td>
                  <FormControl>
                    <OutlinedInput
                      type="number"
                      value={view?.quantity}
                      onChange={(e) => {
                        handleChangeQuantity(e, view?.id);
                      }}
                      endAdornment={
                        <Stack direction={"row"}>
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleEditItemQuantity();
                            }}
                          >
                            <SaveIcon />
                          </IconButton>
                        </Stack>
                      }
                      inputProps={{ min: 0 }}
                      min={0}
                      error={!view?.inputError}
                    />
                    <FormHelperText error>
                      {!view?.inputError &&
                        "Quantity should be 0 or greater than 0"}
                    </FormHelperText>
                  </FormControl>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CustomDialog> */}

      <CustomDialog
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        title={`View Quantity`}
        PDFData={`PDF`}
        ExcelData={`Excel`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
        MyBgColor="#727297"
        ViewDATA={viewData}
        Discounted_Amount={discounted}
      >
        {viewData.map((val) => {
          return (
            <>
              {/* <Typography sx={{ color: "maroon" }}>
                OrderId:<small style={{ color: "darkgreen" }}>{val?._id}</small>{" "}
              </Typography>
              <Typography sx={{ color: "maroon" }}>
                Date:
                <small style={{ color: "darkgreen" }}>{val?.datetime}</small>
              </Typography> */}

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ color: "maroon" }}>
                      OrderId:
                      <small style={{ color: "darkgreen" }}>{val?._id}</small>
                    </Typography>
                    <Typography sx={{ color: "maroon" }}>
                      Date:
                      <small style={{ color: "darkgreen" }}>
                        {val?.datetime}
                      </small>
                    </Typography>
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <TextField
                      id="standard-basic"
                      label="Add Discount"
                      color="success"
                      variant="standard"
                      value={discount}
                      onChange={UserInputChange}
                    />
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      endIcon={<SendIcon />}
                      onClick={() => updateItemDiscount(val?._id, discount)} // Wrap in arrow function
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        })}
        <TableWrapper>
          <TableContainerWrapper component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    SKU
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Rate
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Qty
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Total
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    TPR
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Tax
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Free Pkts
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    CD
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewData?.length > 0
                  ? viewData.map((view, index) =>
                      view.itemid[0].map((_, idx) => {
                        const qty = parseFloat(view?.quantity[0][idx]) || 0;
                        const price = parseFloat(view?.price[0][idx]) || 0;
                        const total = (qty * price).toFixed(2);
                        const tpr =
                          parseFloat(view?.discount[0][idx])?.toFixed(2) || 0;
                        const tax =
                          parseFloat(view?.tax[0][idx])?.toFixed(2) || 0;
                        const freePkts =
                          parseFloat(view?.free[0][idx])?.toFixed(2) || 0;
                        const cd =
                          parseFloat(view?.cash_disc[0][idx])?.toFixed(2) || 0;

                        return (
                          <StyledTableRow key={`${index}-${idx}`}>
                            <TableCell align="center">
                              {view?.itemid[0][idx]}
                            </TableCell>
                            <TableCell align="center">
                              {price.toFixed(2)}
                            </TableCell>
                            <TableCell align="center">
                              {qty.toFixed(2)}
                            </TableCell>
                            <TableCell align="center">{total}</TableCell>
                            <TableCell align="center">{tpr}</TableCell>
                            <TableCell align="center">{tax}</TableCell>
                            <TableCell align="center">{freePkts}</TableCell>
                            <TableCell align="center">{cd}</TableCell>
                          </StyledTableRow>
                        );
                      })
                    )
                  : null}

                {/* Summary Row */}
                <StyledTableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalQty.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalAmount.toFixed(2)}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalTPR.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalTax.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalFreePkts.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalCD.toFixed(2)}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  >
                    Discount:
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  >
                    {discounted}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  >
                    Total After Discount
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  >
                    {discounted
                      ? (Number(totalAmount.toFixed(2)) - discounted).toFixed(2)
                      : null}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainerWrapper>
        </TableWrapper>
      </CustomDialog>

      {/* custome dialog start*/}
      <CustomDialog
        open={openViewDialogImg}
        onClose={() => {
          setOpenViewDialogImg(false);
        }}
        title={`View Image`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
      >
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <CustomLoader open={isLoading} />

          {/* Conditional rendering based on image availability */}
          {image ? (
            <img src={image} alt="image" width={600} height={400} />
          ) : (
            <>
              <h2 style={{ color: "#ff0000a1" }}>No image found</h2>
            </>
          )}
        </div>
      </CustomDialog>

      {/* custome dialog end*/}
    </>
  );
}
