import React, { useState, useEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  TablePagination,
} from "@mui/material";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import * as XLSX from "xlsx";

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#d3d4ee",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
  padding: "8px 12px",
}));

const TableWrapper = styled(Box)({
  width: "100%",
});

const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  height: "350px",
  "& .MuiTableBody-root": {
    overflowY: "auto",
    height: "auto",
    minHeight: "calc(6 * 56px)",
  },
}));

// Format number into readable format
const formatNumber = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M"; // Convert to millions
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K"; // Convert to thousands
  } else {
    return number.toLocaleString(); // Add commas for thousands
  }
};

const ItemIdChartExportExcel = ({
  customDateRange,
  selectedItemIdView,
  setOpenDialog,
  selectedSalesgroup,
  selectedStockist,
  selectedNames,
}) => {
  const [viewData, setViewData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // Debounced search term
  const [totalKg, setTotalKg] = useState(0);
  const [totalPkt, setTotalPkt] = useState(0);
  const [data, setData] = useState([]);

  const fetchQuantityData = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();

      params.append("page", currentPage + 1);
      params.append("per_page", rowsPerPage);

      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (selectedItemIdView) {
        params.append("itemId", selectedItemIdView);
      }
      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", selectedStockist);
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }
      // Search query
      if (searchTerm) {
        params.append("query_string", searchTerm);
      }

      const url = `/api/item_history?${params.toString()}`;
      const response = await getRequestInstanceOne(url);
      const totalRecords = response.data[0].totalCount[0].total;
      const totalKgValue = response.data[0].total_kg[0]?.total_kg || 0; // Handle cases where total_kg might be undefined
      const totalPktValue = response.data[0].total_pkt[0]?.total_pkt || 0; // Handle cases where total_kg might be undefined

      setViewData(response.data[0].results);
      setFilteredData(response.data[0].results); // Initialize filtered data
      setTotalKg(totalKgValue);
      setTotalPkt(totalPktValue);
      setTotalCount(totalRecords);

      setOpenDialog(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Debouncing search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(0);
    }, 1000); // Delay of 1000ms

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    fetchQuantityData();
  }, [
    selectedItemIdView,
    currentPage,
    rowsPerPage,
    customDateRange,
    selectedSalesgroup,
    selectedStockist,
    selectedNames,
    debouncedSearchTerm, // Trigger data fetch when debounced search term changes
  ]);

  useEffect(() => {
    fetchQuantityData();
  }, [selectedItemIdView]);

  // Filter data based on search term
  useEffect(() => {
    const trimmedSearchTerm = searchTerm.trimEnd().toLowerCase(); // Trim trailing spaces and convert to lowercase
    if (trimmedSearchTerm) {
      const newFilteredData = viewData.filter((item) =>
        [
          item.salesgroup,
          item.orderId,
          item.payerId,
          item.stockist,
          item.customer,
          item.area,
          item.orderbyName,
        ].some(
          (field) => field && field.toLowerCase().includes(trimmedSearchTerm)
        )
      );
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(viewData);
    }
  }, [debouncedSearchTerm, viewData]);

  // Calculate total quantity

  // Export excel

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Prepare the query parameters
        const params = new URLSearchParams({
          start_date: customDateRange.start_date,
          end_date: customDateRange.end_date,
          itemId: selectedItemIdView,
        });

        // Make API call using getRequestInstanceOne
        const url = `/api/item_history?${params.toString()}`;
        const response = await getRequestInstanceOne(url);

        // Assuming the response is in a format where the data is an array
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [
    customDateRange.start_date,
    customDateRange.end_date,
    selectedItemIdView,
  ]);

  const exportToExcel = () => {
    if (!data.length) {
      alert.warn("No data to export");
      return;
    }

    // Define the data to export
    const exportData = data.map((item) => ({
      Area: item.area,
      Cash_disc: item.cash_disc,
      Customer: item.customer,
      Free: item.free,
      OrderId: item.orderId,
      OrderOn: item.orderOn,
      OrderbyName: item.orderbyName,
      PayerId: item.payerId,
      Price: item.price,
      Qty: item.qty,
      Salesgroup: item.salesgroup,
      Stockiest: item.stockist,
      Discount: item.discount,
    }));

    // Create a new workbook and a worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Item History");

    // Generate the Excel file
    XLSX.writeFile(
      workbook,
      `${selectedItemIdView}_${customDateRange.start_date}_${customDateRange.end_date}.xlsx`
    );
  };

  // Making text ellipsis... start

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Making text ellipsis... end

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <>
      <CustomLoader open={isLoading} />
      <Box sx={{ mb: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: searchTerm && (
                <IconButton
                  onClick={() => setSearchTerm("")}
                  aria-label="clear"
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
            sx={{ width: "30%" }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "#604e8d",
              padding: "8px",
              borderRadius: "4px",
              color: "wheat",
            }}
          >
            Total Quantity: {`${totalKg}kg`}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "#604e8d",
              padding: "8px",
              borderRadius: "4px",
              color: "wheat",
            }}
          >
            Total Quantity: {`${totalPkt}Pkt`}
          </Typography>
          <Button
            size="small"
            variant="contained"
            onClick={exportToExcel}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              p: 1,
            }}
            startIcon={<SaveAltIcon />}
          >
            Export Excel
          </Button>
        </Box>
        <TableWrapper sx={{ paddingTop: 0 }}>
          <TableContainerWrapper component={Paper} style={{ height: "77vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    SalesGroup
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Order ID
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Payer ID
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Stockist
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Customer
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Area
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Order By Name
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Location
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Price
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Order On
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Cash Discount
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Discount
                  </StyledTableCell>
                  <StyledTableCell>Free</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item, index) => (
                  <StyledTableRow key={index}>
                    <Tooltip
                      title={item.salesgroup || "-"}
                      placement="top"
                      arrow
                    >
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.salesgroup || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.orderId || "-"} placement="top" arrow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.orderId || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.payerId || "-"} placement="top" arrow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.payerId || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.stockist || "-"} placement="top" arrow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.stockist || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.customer || "-"} placement="top" arrow>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.customer || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.area || "-"} placement="top" arrow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.area || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip
                      title={item.orderbyName || "-"}
                      placement="top"
                      arrow
                    >
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.orderbyName || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.longitude && item.lattitude ? (
                        <a
                          href={`https://www.google.com/maps?q=${item.longitude},${item.lattitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton>
                            <LocationOnIcon />
                          </IconButton>
                        </a>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.price}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.qty}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.orderOn}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.cash_disc}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.discount}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.free}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainerWrapper>
        </TableWrapper>
        {/* Pagination Controls */}
        <TablePagination
          component="div"
          count={totalCount}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </>
  );
};

export default ItemIdChartExportExcel;
