import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";

const CustomDialog = ({
  open,
  onClose,
  title,
  children,
  fullscreen = false,
  maxWidth = "xs",
  MyBgColor,
  PDFData,
  ExcelData,
  ViewDATA,
  Discounted_Amount,
}) => {
  // pdf

  // Helper function to convert image URL to base64
  const getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const generatePDF = async (data) => {
    const doc = new jsPDF();

    // Fetch the image and convert to base64
    const logoBase64 = await getBase64FromUrl("/sapat-logo.png");

    // Add header information
    const date = data[0]?.datetime?.[0] || "N/A";
    const id = data[0]?._id || "N/A";
    const deliveredBy = data[0]?.stockistname?.[0] || "N/A";
    const retailer = data[0]?.retailername?.[0] || "N/A";
    const BeatName = data[0]?.beat_name || "N/A";

    // Add the image to the top-right corner
    doc.addImage(logoBase64, "JPEG", 150, 15, 53, 20); // Adjust position (x, y) and size (width, height)

    // Add text below the image
    doc.setFontSize(8.5);
    doc.text(" Sapat International Pvt. Ltd.", 160, 38); // Adjust position (x, y) based on image placement

    // Add text information to the left side
    doc.setFontSize(12);
    doc.text(`Date: ${date}`, 14, 20);
    doc.text(`ID: ${id}`, 14, 30);
    doc.text(`Delivered By: ${deliveredBy}`, 14, 40);
    doc.text(`To: ${retailer}`, 14, 50);
    doc.text(`Beat Name: ${BeatName}`, 14, 60);

    // Table headers and rows
    const tableHeaders = [
      "SKU",
      "Rate",
      "Qty",
      "Total",
      "TPR",
      "Tax",
      "Free Pkts",
      "CD",
    ];

    const itemid = data[0]?.itemid?.[0] || [];
    const price = data[0]?.price?.[0] || [];
    const quantity = data[0]?.quantity?.[0] || [];
    const tax = data[0]?.tax?.[0] || [];
    const free = data[0]?.free?.[0] || [];
    const cash_disc = data[0]?.cash_disc?.[0] || [];

    const tableRows = itemid.map((_, index) => [
      itemid[index] || "N/A",
      price[index] || "0",
      quantity[index] || "0",
      (
        parseFloat(price[index] || 0) * parseFloat(quantity[index] || 0)
      ).toFixed(2),
      0, // TPR - Placeholder, adjust as necessary
      tax[index] || "0",
      free[index] || "0",
      cash_disc[index] || "0",
    ]);

    // Generate the table
    autoTable(doc, {
      head: [tableHeaders],
      body: tableRows,
      startY: 70,
    });

    // Calculate totals
    const totalQty = quantity.reduce(
      (acc, qty) => acc + parseFloat(qty || 0),
      0
    );
    const totalAmount = price
      .reduce(
        (acc, price, index) =>
          acc + parseFloat(price || 0) * parseFloat(quantity[index] || 0),
        0
      )
      .toFixed(2);
    const totalTax = tax
      .reduce((acc, tax) => acc + parseFloat(tax || 0), 0)
      .toFixed(2);
    const totalFree = free.reduce(
      (acc, freeQty) => acc + parseFloat(freeQty || 0),
      0
    );
    const totalCD = cash_disc.reduce(
      (acc, disc) => acc + parseFloat(disc || 0),
      0
    );

    // Add totals row
    const totalsRow = [
      "Total",
      "", // No total for SKU
      totalQty.toFixed(2),
      totalAmount,
      "0", // TPR total - adjust as needed
      totalTax,
      totalFree.toFixed(2),
      totalCD.toFixed(2),
    ];
    const totalsRow1 = ["Discount", "", "", Discounted_Amount, "", "", "", ""];
    const totalsRow2 = [
      "Discounted Amount",
      "",
      "",
      totalAmount - Discounted_Amount,
      "",
      "",
      "",
      "",
    ];

    // Generate the table with the totals row
    autoTable(doc, {
      head: [tableHeaders],
      body: [...tableRows, totalsRow, totalsRow1, totalsRow2],
      startY: 70,
      didParseCell: (data) => {
        // Apply bold style to the totals row
        if (data.row.index === tableRows.length) {
          data.cell.styles.fontStyle = "bold";
        }
      },
    });

    // Save the PDF
    doc.save(`${id}_${deliveredBy}.pdf`);
  };

  // Excel

  const exportToExcel = (data) => {
    const id = data[0]?._id || "N/A";
    const deliveredBy = data[0]?.stockistname?.[0] || "N/A";
    const BeatName = data[0]?.beat_name || "N/A";

    // Prepare the data in the format needed for the Excel file
    const tableHeaders = [
      ["SKU", "Rate", "Qty", "Total", "TPR", "Tax", "Free Pkts", "CD"],
    ];

    const itemid = data[0]?.itemid?.[0] || [];
    const price = data[0]?.price?.[0] || [];
    const quantity = data[0]?.quantity?.[0] || [];
    const tax = data[0]?.tax?.[0] || [];
    const free = data[0]?.free?.[0] || [];
    const cash_disc = data[0]?.cash_disc?.[0] || [];

    const tableRows = itemid.map((_, index) => [
      itemid[index] || "N/A",
      parseFloat(price[index] || 0).toFixed(2),
      parseFloat(quantity[index] || 0).toFixed(2),
      (
        parseFloat(price[index] || 0) * parseFloat(quantity[index] || 0)
      ).toFixed(2),
      0, // TPR - Placeholder, adjust as necessary
      parseFloat(tax[index] || 0).toFixed(2),
      parseFloat(free[index] || 0).toFixed(2),
      parseFloat(cash_disc[index] || 0).toFixed(2),
    ]);

    // Calculate totals
    const totalRate = price
      .reduce((acc, rate) => acc + parseFloat(rate || 0), 0)
      .toFixed(2);
    const totalQty = quantity
      .reduce((acc, qty) => acc + parseFloat(qty || 0), 0)
      .toFixed(2);
    const totalAmount = price
      .reduce(
        (acc, price, index) =>
          acc + parseFloat(price || 0) * parseFloat(quantity[index] || 0),
        0
      )
      .toFixed(2);
    const totalTax = tax
      .reduce((acc, tax) => acc + parseFloat(tax || 0), 0)
      .toFixed(2);
    const totalFree = free
      .reduce((acc, freeQty) => acc + parseFloat(freeQty || 0), 0)
      .toFixed(2);
    const totalCD = cash_disc
      .reduce((acc, disc) => acc + parseFloat(disc || 0), 0)
      .toFixed(2);

    // Add totals row
    const totalsRow = [
      "Total",
      totalRate, // Sum of rates
      totalQty,
      totalAmount,
      "0", // TPR total - adjust as needed
      totalTax,
      totalFree,
      totalCD,
    ];

    const totalsRow1 = ["Discount", "", "", Discounted_Amount, "", "", "", ""];
    const totalsRow2 = [
      "Discounted Amount",
      "",
      "",
      totalAmount - Discounted_Amount,
      "",
      "",
      "",
      "",
    ];

    // Add header information
    const tableHeadersID = [["PayerID", id]];
    const tableHeaders_deliveredBy = [["Delivered By", deliveredBy]];
    const tableHeaders_BeatName = [["Beat Name", BeatName]];

    // Combine headers and rows
    const excelData = [
      ...tableHeadersID,
      ...tableHeaders_deliveredBy,
      ...tableHeaders_BeatName,
      ...tableHeaders,
      ...tableRows,
      totalsRow, // Append the totals row
      totalsRow1, // Append the discount row
      totalsRow2, // Append the discounted amount row
    ];

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");

    // Export the file
    XLSX.writeFile(workbook, `${id}_${deliveredBy}.xlsx`);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={true}
      fullScreen={fullscreen}
    >
      <DialogTitle
        id="customized-dialog-title"
        // sx={{ bgcolor: "#5052ba", color: "white" }}
        sx={{ bgcolor: MyBgColor ? MyBgColor : "#5052ba", color: "white" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {title}
          </Grid>
          <Grid item xs={1}>
            {PDFData ? (
              <Button
                sx={{ color: "white", border: "1px solid #c7b4b4" }}
                onClick={() => generatePDF(ViewDATA)}
              >
                {PDFData}
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={2}>
            {ExcelData ? (
              <Button
                sx={{ color: "white", border: "1px solid #c7b4b4" }}
                onClick={() => exportToExcel(ViewDATA)}
              >
                {ExcelData}
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ paddingTop: "2px" }}>{children}</DialogContent>
      <Divider />
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([
    PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    PropTypes.bool,
  ]),
};

export default CustomDialog;
